<template>
  <div class="certificateInfoWrap">
    <div class="topWrap">
      <div>
        <i class="el-icon-arrow-left"></i>
        <span>{{ title }}</span>
      </div>
      <el-button v-if="status == 1" round class="checkingBtn">审核中</el-button>
      <el-button
        v-if="status == 2"
        round
        class="checkBtn"
        type="primary"
        @click="checkImg"
        >查看证书</el-button
      >
    </div>
    <el-divider></el-divider>
    <div v-html="content"></div>
    <ImgDialog ref="ImgDialog"></ImgDialog>
  </div>
</template>

<script>
import ImgDialog from "./imgDialog.vue";
export default {
  components: {
    ImgDialog,
  },
  data() {
    return {
      title: "证书名称证书名称证书名称证书名称证书名称证书名称证书名称证书名称",
      status: 2,
      content: "",
      img: "https://z3.ax1x.com/2021/11/11/IwNP6x.png",
    };
  },
  mounted() {},
  methods: {
    checkImg() {
      this.$refs.ImgDialog.openInit(this.img);
    },
  },
};
</script>

<style scoped lang="scss">
.certificateInfoWrap {
  background: #fff;
  padding: 24px;
  box-sizing: border-box;
  .topWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      i {
        margin-right: 5px;
        cursor: pointer;
        font-size: 16px;
      }
    }
    .checkingBtn {
      padding: 10px 23px;
      background:#fff;border:1px solid var(--change-color);
      border: 1px solid transparent;
      color: var(--change-color);
    }
    .checkBtn {
      padding: 10px 23px;
    }
  }
  /deep/.el-divider--horizontal {
    margin: 15px 0;
  }
}
</style>
